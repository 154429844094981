import React, { Component } from 'react';
import { Container, Header, Button, Card, Modal, Image, Grid, Responsive } from 'semantic-ui-react';

import Article1 from '../Pics/Art1.pdf';
import Article2 from '../Pics/Art2.pdf';
import Article3 from '../Pics/Art3.pdf';
import Article4 from '../Pics/Art4.pdf';


export default class Articles extends Component {
  render() {
    const articles = [
      { title: 'כך האב זכה במשמורת משותפת', pdf: Article1 },
      { title: 'כל מה שרציתם לדעת על דמי הבראה', pdf: Article2 },
      { title: 'נדל"ן בחו"ל: הטעיה או עסקה כשרה?', pdf: Article3 },
      { title: 'הדירה התבררה פתאום כמחסן', pdf: Article4 },
    ];

    return (
      <Container dir="rtl" style={{ padding: '2em 0' }}>
        <Header as="h1" textAlign="center" style={{ marginBottom: '2em' }}>
          <u>עו"ד רונית גוטשל מפרסמת מאמרים וסקירות פסיקה בכתבי עת שונים.</u>
        </Header>

        <Grid stackable columns={2} doubling>
          {articles.map((article, index) => (
            <Grid.Column key={index}>
              <Card fluid>
                <Card.Content>
                  <Card.Header>{article.title}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                  <Modal
                    closeIcon
                    trigger={
                      <Button basic color="green" fluid>
                        קרא את הכתבה
                      </Button>
                    }
                  >
                    <Modal.Content scrolling>
                      <iframe
                        src={article.pdf}
                        style={{ width: '100%', height: '80vh', border: 'none' }}
                        title={article.title}
                      ></iframe>
                    </Modal.Content>
                  </Modal>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid>

      </Container>
    );
  }
}

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    direction: "rtl",
    backgroundColor: '#001f3f', // Solid dark background color
    color: 'white',
    padding: '40px 0',
    justifyContent: 'center',
    marginTop: "auto",
    borderTop: '3px solid #f39c12', // Border for distinction
  },
  footer: {
    padding: '40px 0',
    backgroundColor: '#001f3f',
    color: 'white',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  logo: {
    width: "100%",
    maxWidth: "150px",
    transition: "transform 0.3s ease-in-out",
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  contactSection: {
    display: 'flex',
    flexDirection: 'row', // Align icons in a single row
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    gap: '20px', // Add spacing between icons
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  icon: {
    height: '50px', // Consistent height for all icons
    width: 'auto', // Proportional scaling
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.2)', // Add hover effect
      filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))', // Subtle shadow on hover
    },
  },
  text: {
    color: 'white',
    fontSize: '1rem',
  },
//   link: {
//     color: '#f39c12',
//     textDecoration: 'none',
//     fontWeight: 'bold',
//     '&:hover': {
//       color: '#f1c40f',
//     },
//   },
  link: {
   color: 'white !important', // Explicitly set to white
   textDecoration: 'none',
   fontSize: '18px', // Slightly larger font
   fontWeight: 'bold',
   transition: 'color 0.3s ease',
   '&:hover': {
     color: '#ffcc00 !important', // Gold hover effect
   },
 },
}));

import React, { Component } from 'react';
import { Container, Image, Grid, Header, List, Segment } from 'semantic-ui-react';
import AdiPic from '../Pics/AdiPic.png';
import RonitPic from '../Pics/RonitPic.png';

export default class Staff extends Component {
  render() {
    const rtlListStyle = {
      paddingRight: '1.5em', // Space between the bullets and text
      textAlign: 'right',    // Align text to the right
      direction: 'rtl',      // Set the direction explicitly
      listStylePosition: 'inside', // Ensure the bullets are inside the text box
    };
    
    const listItemStyle = {
      marginBottom: '0.5em', // Add space between list items
    };
    
    
    
    const staffMembers = [
      {
        name: 'גוטשל רונית, עורכת דין',
        image: RonitPic,
        description: `עו"ד עצמאית, חברה בלשכת עורכי הדין משנת 1997, מקימת המשרד, בעלת ניסיון מעשי עשיר בתחום האזרחי, מומחית בתחום המקרקעין בדיני עבודה ובדיני משפחה. יצוגית, בעלת כושר שכנוע ויכולת ניהול מו"מ בכל הרמות. התנסחות בכתב ובע"פ ברמה גבוה.`,
        qualifications: [
          'בעלת תואר ראשון  B.Aבמדעי המדינה.',
          'תואר L.L.B.  במשפטים.',
          'לימודי כלכלה במסגרת התואר הראשון.',
          'לימודי התנהגות ארגונית במסגרת תואר שני.',
          'בוגרת מסלול ניהול של הבנק הבינ"ל הראשון.',
          'התמחות בביה"ד האזורי לעבודה בתל אביב.',
          'מגשרת מוסמכת על ידי המרכז הישראלי לגישור ואימון גומא.',
        ],
      },
      {
        name: 'גוטשל עדי, עורך דין',
        image: AdiPic,
        description: `עו"ד עצמאי, הצטרף למשרד בשנת 2010. בעל ניסיון מעשי עשיר בתחום האזרחי, דיני עבודה ודיני חברות כמעסיק. מומחה בדיני משפחה. ניסיון בניהול מתקדם רב תחומי של תהליכים משפטיים מקביליים, מורכבים ורגישים. יצוגי, בעל כושר שכנוע ויכולת ניהול מו"מ בכל הרמות. התנסחות בכתב ובע"פ ברמה גבוה.`,
        qualifications: [
          'טייס מסוקים לשעבר בחיל האוויר.',
          'בעל תואר ראשון B.Sc  בהצטיינות בהנדסת תעשיה וניהול.',
          'בעל תואר שני במנהל עסקים.',
          'בעל תואר L.L.B במשפטים.',
          'לשעבר מנכ"ל וסמנכ"ל בחברות היי-טק לרבות אחריות על כל הפן המשפטי של החברות.',
        ],
      },
    ];

    return (
      <Container dir="rtl" style={{ padding: '2em 0' }}>
        <Header as="h1" textAlign="center" style={{ marginBottom: '2em', textDecoration: 'underline' }}>
          צוות המשרד
        </Header>

        <Grid stackable columns={2}>
          {staffMembers.map((member, index) => (
            <Grid.Column key={index}>
              <Segment raised>
                <Grid>
                  <Grid.Column width={6} textAlign="center">
                    <Image src={member.image} circular size="medium" alt={member.name} />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as="h2" style={{ marginBottom: '1em' }}>
                      {member.name}
                    </Header>
                    <p style={{ fontSize: '1.2em', lineHeight: '1.8' }}>{member.description}</p>
                    <ul style={rtlListStyle}>
  {member.qualifications.map((qualification, idx) => (
    <li key={idx} style={listItemStyle}>{qualification}</li>
  ))}
</ul>



                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          ))}
        </Grid>
      </Container>
    );
  }
}

// src/components/Footer.js
import React from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './Footer.css';
import Logo from "../../Pics/Logo.png";
import waze from "../../Pics/waze3.png";
import whatsapp from "../../Pics/WhatsAppButtonGreenMedium.png";
import callus from "../../Pics/callus.png";
import emailus from "../../Pics/emailus.png";
import { CONTACT_URL } from "../../constants/Urls";


const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Grid container spacing={4} className={classes.root} alignItems="center" justifyContent="space-between">
                    {/* Logo Section */}
                    <Grid item xs={12} sm={3} className={classes.logoContainer}>
                    <Link to={CONTACT_URL} className={classes.link}>
                    <img src={Logo} alt="Logo" className={classes.logo} />                    
                        <Typography variant="h4" className={classes.sectionHeader}>
        לחצו בשביל ליצור קשר
      </Typography>
      </Link>
                    </Grid>

                    {/* Contact Info with Logos */}
                    <Grid item xs={12} sm={9} className={classes.contactSection}>
                        <div className={classes.iconsRow}>
                            <a href="https://wa.link/mcntvm">
                                <img src={whatsapp} alt="WhatsApp" className={classes.icon} />
                            </a>
                            <a href="https://waze.com/ul/hsv8y3529c" className={classes.link}>
                                <img src={waze} alt="Waze" className={classes.icon} />
                            </a>                            
                            <a href="tel:035711797">
                                <img src={callus} alt="Call Us" className={classes.icon} />
                            </a>
                            <a href="mailto:gotshal.law@gmail.com">
                                <img src={emailus} alt="Email Us" className={classes.icon} />
                            </a>
                            
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;

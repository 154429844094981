import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  MenuItem,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { ExpandMore, ExpandLess, Menu as MenuIcon } from '@material-ui/icons';
import Logo2 from '../../Pics/Logo2.png';
import useStyles from './Navbar.css';
import { WORK_URL, FAMILY_URL, GROUND_URL, ABOUT_URL, CONTACT_URL, LECTURES_URL, TEAM_URL, STORIES_URL, RECOMMENDATIONS_URL } from "../../constants/Urls";

const Navbar = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMobileItemClick = () => {
    setMobileOpen(false); // Close the mobile menu when an item is clicked
  };

  const navItems = [
    { label: 'אודותינו', link: ABOUT_URL },
    { label: 'צוות המשרד', link: TEAM_URL },
    {
      label: 'תחומי התמחות',
      dropdown: [
        { label: 'דיני עבודה', link: WORK_URL },
        { label: 'דיני מקרקעין', link: GROUND_URL },
        { label: 'דיני משפחה', link: FAMILY_URL },
      ],
    },
    { label: 'כתבות', link: STORIES_URL },
    { label: 'הרצאות', link: LECTURES_URL },
    { label: 'צור קשר', link: CONTACT_URL },
    { label: 'לקוחות מודים', link: RECOMMENDATIONS_URL },
  ];

  return (
    <header className={classes.root}>
      <Grid container alignItems="center" className={classes.navContainer}>
        <Grid item>
          <Link to="/">
            <img src={Logo2} alt="Logo" className={classes.logo} />
          </Link>
        </Grid>

        {/* Desktop Menu */}
        <Grid item className={classes.menuList}>
          {navItems.map((item, index) =>
            item.dropdown ? (
              <div
                key={index}
                className={classes.dropdownContainer}
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <Button className={classes.link}>
                  {item.label}
                  {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
                </Button>
                {dropdownOpen && (
                  <List className={classes.dropdownMenu}>
                    {item.dropdown.map((subItem, subIndex) => (
                      <Link to={subItem.link} className={classes.link} key={subIndex}>
                        <ListItem button>
                          <ListItemText primary={subItem.label} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                )}
              </div>
            ) : (
              <Link to={item.link} key={index} className={classes.link}>
                <Button className={classes.link}>{item.label}</Button>
              </Link>
            )
          )}
        </Grid>

        {/* Mobile Menu Icon */}
        <Grid item>
          <IconButton className={classes.menuIcon} onClick={toggleMobileMenu}>
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={mobileOpen} onClose={toggleMobileMenu}>
        <List className={classes.mobileMenu}>
          {navItems.map((item, index) =>
            item.dropdown ? (
              <div key={index}>
                <ListItem button onClick={toggleDropdown}>
                  <ListItemText primary={item.label} />
                  {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={dropdownOpen} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {item.dropdown.map((subItem, subIndex) => (
                      <Link
                        to={subItem.link}
                        className={classes.link}
                        key={subIndex}
                        onClick={handleMobileItemClick} // Close the menu on item click
                      >
                        <ListItem button>
                          <ListItemText primary={subItem.label} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              </div>
            ) : (
              <Link
                to={item.link}
                key={index}
                className={classes.link}
                onClick={handleMobileItemClick} // Close the menu on item click
              >
                <ListItem button>
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            )
          )}
        </List>
      </Drawer>
    </header>
  );
};

export default Navbar;

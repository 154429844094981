import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: '#001f3f !important', // Dark blue background
    color: 'white !important',
    padding: '10px 20px',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  navContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    height: '60px',
    cursor: 'pointer',
  }, 
  menuList: {
    display: 'flex',
    gap: '30px',
    order: 2, // Ensure the menu list is placed after the logo
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse', // Right to Left on Desktop
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide menu on mobile
    },
  },
  menuIcon: {
    display: 'none',
    color: 'white !important', // Ensures menu icon is visible
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  link: {
    color: 'white !important', // Explicitly set to white
    textDecoration: 'none',
    fontSize: '18px', // Slightly larger font
    fontWeight: 'bold',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#ffcc00 !important', // Gold hover effect
    },
  },
  dropdownContainer: {
    position: 'relative',
    direction: 'rtl'
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: '#001f3f !important', // Match navbar background
    boxShadow: theme.shadows[3],
    borderRadius: '4px',
    padding: '10px 0',
    '& a': {
      color: 'white !important', // Explicitly set to white
      padding: '10px 20px',
      display: 'block',
      textDecoration: 'none',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: '#002244 !important',
        color: '#ffcc00 !important', // Gold hover effect
      },
    },
  },
  mobileMenu: {
    width: '250px',
    backgroundColor: '#001f3f !important',
    height: '100%',
    color: 'white !important',
    '& a': {
      color: 'white !important', // Explicitly set to white
      fontSize: '18px',
      padding: '10px 20px',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: '#002244',
        color: '#ffcc00 !important', // Gold hover effect
      },
    },
  },
}));